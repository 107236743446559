type PageName = 'SWAP' | 'PORTFOLIOS' | 'SINGLE_SIDE' | 'FARMING' | 'STAKING' | 'BRIDGE';
export const ROUTE: Readonly<Record<PageName, string>> = {
  SWAP: '/swap',
  PORTFOLIOS: '/portfolios',
  SINGLE_SIDE: '/single-side',
  FARMING: '/farming',
  STAKING: '/staking',
  BRIDGE: '/bridge',
};

export const ROUTE_NAMES: Readonly<Record<PageName, string>> = {
  SWAP: 'Swap',
  PORTFOLIOS: 'Portfolios',
  SINGLE_SIDE: 'SingleSide',
  FARMING: 'Farming',
  STAKING: 'Staking',
  BRIDGE: 'Bridge',
};
