<template>
  <div
    class="flex w-full min-h-screen flex-column justify-content-between layout"
    :class="`layout-${$store.state.layout.viewMode} ${bgClass()}`"
  >
    <Head>
      <meta property="twitter:card" name="twitter:card" :content="SEO_META_TWITTER.TWITTER_CARD" />
      <meta
        property="twitter:title"
        name="twitter:title"
        :content="SEO_META_TWITTER.TWITTER_TITLE"
      />
      <meta
        property="twitter:description"
        name="twitter:description"
        :content="SEO_META_TWITTER.TWITTER_DESCRIPTION"
      />
      <meta
        property="twitter:image"
        name="twitter:image"
        :content="SEO_META_TWITTER.PREVIEW_IMAGE"
      />
      <meta property="og:title" name="og:title" :content="SEO_META_OG.OG_TITLE" />
      <meta property="og:site_name" name="og:site_name" :content="SEO_META_OG.OG_SITE_NAME" />
      <meta property="og:type" name="og:type" :content="SEO_META_OG.OG_TYPE" />
      <meta property="og:description" name="og:description" :content="SEO_META_OG.OG_DESCRIPTION" />
      <meta property="og:image" name="og:image" :content="SEO_META_OG.OG_IMAGE" />
    </Head>
    <Loader v-if="isLoadingUi"></Loader>
    <template v-else>
      <DegenLayerLabel as-panel />
      <SpicePoints v-if="isMobile" />
      <div
        class="flex w-full mx-auto content flex-grow-1 flex-column"
        :class="[$store.state.layout.viewMode, { 'footer-visible': isBigScreen }]"
      >
        <BannersCarousel v-if="!isNetworkDisabledToAdd" />
        <DisablingNetworks v-else />
        <Breadcrumbs />
        <NetworkNotSupported v-if="isNetworkSupported === false" />
        <div class="flex flex-column mb-s14" v-else>
          <router-view />
        </div>
      </div>
      <Footer />
      <Notifications />
      <ConfirmDialog />
      <Header />
    </template>
  </div>
</template>

<script>
import Header from './header/Header.vue';
import Footer from './footer/Footer.vue';
import { MODULE_NAMES } from '@/store';
import { mapActions, mapGetters } from 'vuex';
import { toRefs } from 'vue';
import { LAYOUT_ACTION_TYPES } from '@/store/modules/layout/layout.module';
import Loader from '../../components/Loader';
import Notifications from './notifications/Notifications';
import NetworkNotSupported from './error/NetworkNotSupported';
import { seo } from '@/composables/seo/useSeo';
import { Head } from '@vueuse/head';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';
import { BREAKPOINTS, LAYOUT_MODE } from '@/constants/LAYOUT_PARAMS';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DegenLayerLabel from '@/components/degenLayer/DegenLayerLabel.vue';
import { checkIfNetworkIdDisabledToAdd } from '@/helpers/network-disable.helper';
import { computed } from 'vue';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import DisablingNetworks from '@/components/disablingNetworks/DisablingNetworks.vue';
import SpicePoints from '@/components/SpicePoints.vue';
import { useSpicePoints } from '@/composables/spice-points/useSpicePoints';
import BannersCarousel from '@/components/BannersCarousel.vue';

export default {
  name: 'Layout',
  components: {
    DisablingNetworks,
    BannersCarousel,
    DegenLayerLabel,
    SpicePoints,
    NetworkNotSupported,
    Notifications,
    Loader,
    Footer,
    Header,
    Head,
    Breadcrumbs,
  },
  setup() {
    const { walletState } = useEVMWallet();

    const isNetworkDisabledToAdd = computed(() =>
      checkIfNetworkIdDisabledToAdd(DEFAULT_NETWORK_ID),
    );

    const { isEnabledSpicePoints } = toRefs(useSpicePoints());

    return {
      walletState,
      isNetworkDisabledToAdd,
      isEnabledSpicePoints,
    };
  },
  data() {
    return {
      isLoad: true,
      isBigScreen: window.innerWidth > BREAKPOINTS.XXL,
    };
  },
  computed: {
    ...mapGetters(MODULE_NAMES.LAYOUT, ['isMobile', 'isLoadingUi']),
    SEO_META_OG() {
      return seo.value.SEO_META_OG;
    },
    SEO_META_TWITTER() {
      return seo.value.SEO_META_TWITTER;
    },
    isNetworkSupported() {
      return this.walletState.isNetworkSupported;
    },
  },
  methods: {
    setLayout() {
      const cls = Object.values(LAYOUT_MODE);
      if (window.innerWidth < BREAKPOINTS.SM) {
        this.setMobile();
      } else if (window.innerWidth >= BREAKPOINTS.SM && window.innerWidth < BREAKPOINTS.XL) {
        this.setTablet();
      } else {
        this.setDesktop();
      }
      document.body.classList.remove(...cls);
      document.body.classList.add(this.$store.state.layout.viewMode);
      this.isBigScreen = window.innerWidth > BREAKPOINTS.XXL;
    },
    ...mapActions(MODULE_NAMES.LAYOUT, {
      setMobile: LAYOUT_ACTION_TYPES.SET_MOBILE,
      setTablet: LAYOUT_ACTION_TYPES.SET_TABLET,
      setDesktop: LAYOUT_ACTION_TYPES.SET_DESKTOP,
    }),
    bgClass() {
      return this.isLoadingUi ? 'loading' : `${this.$route.name.toString().toLowerCase()}-page`;
    },
  },
  beforeMount() {
    this.setLayout();
  },
  mounted() {
    window.addEventListener('resize', this.setLayout);
  },
};
</script>

<style scoped lang="scss"></style>
